import { AuthProfileOutput } from '@grandstand/presentation-models/types/responses/profile'
import { useRouter } from 'next/router'
import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'
import { getDeviceCategory } from '../../hooks/useDeviceInfo'
import { mapPathnameToPageTitle } from '../analytics/AnalyticService'
import { DeviceRegion, UserServiceContext } from '../user/UserService'
import { getFavoriteTeams, getSubRegion, getTealiumEntitlement, parseDeviceRegionForTealium } from './tealiumHelpers'
import {
  TealiumCheckoutParams,
  TealiumCreateAccountParams,
  TealiumFavoriteTeamParams,
  TealiumLoginMVPDParams,
  TealiumLoginParams,
  TealiumPackagesParams,
  TealiumPauseSubscriptionParams,
  TealiumPurchaseParams,
  TealiumSelectPackageParams,
  TealiumService,
  TealiumUniversalParams,
  TealiumUpdatePaymentMethodParams,
  TealiumUpgradeToAnnualParams,
  TrackParams
} from './types'

export const TealiumServiceContext = createContext<TealiumService>({} as TealiumService)

type PropsForProvider = PropsWithChildren<{
  app: 'web' | 'connected-web'
}>

const useTealiumServiceProvider = (currentUser?: AuthProfileOutput, deviceRegion?: DeviceRegion) => {
  const universalParams = useMemo<TealiumUniversalParams>(() => {
    const analytics = currentUser?.analytics?.default
    return {
      user_id: analytics?.user_id ?? analytics?.auth_userid,
      user_zip: analytics?.user_zip,
      mvpd_provider: analytics?.mvpd_provider,
      device_category: getDeviceCategory(),
      fav_team: getFavoriteTeams(currentUser),
      sub_region: getSubRegion(analytics),
      entitlement: getTealiumEntitlement(analytics),
      ...parseDeviceRegionForTealium(deviceRegion)
      // hem: // should be hashed + returned by middleware
    }
  }, [currentUser, deviceRegion])

  const tealium = createTealiumService(universalParams)
  return {
    tealium,
    universalParams
  }
}

export const TealiumServiceProvider = ({ children, app }: PropsForProvider) => {
  const userService = useContext(UserServiceContext)
  const { currentUser, deviceRegion } = userService
  const router = useRouter()
  const { tealium, universalParams } = useTealiumServiceProvider(currentUser, deviceRegion)

  useEffect(() => {
    const handlePageView = (app: 'web' | 'connected-web', pathname: string) => {
      // do nothing if window.utag.view is not defined
      if (typeof window?.utag?.view === 'undefined') {
        return
      }

      // do not log pageviews for video, live-event, vod, game-details
      const routesToIgnore = ['/video/', '/live-event/', '/vod/', '/game-details/']
      if (routesToIgnore.some((routeToIgnore) => pathname.includes(routeToIgnore))) {
        return
      }

      // log pageview
      window.utag.view({
        page_name: mapPathnameToPageTitle(app, pathname.split('?')[0]), // ensure no query string
        ...universalParams
      })
    }

    handlePageView(app, router.asPath)

    const handleRouteChangeComplete = (pathname: string) => {
      handlePageView(app, pathname)
    }
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <TealiumServiceContext.Provider value={tealium}>{children}</TealiumServiceContext.Provider>
}

export const useTealiumFromUserService = (currentUser?: AuthProfileOutput, deviceRegion?: DeviceRegion) => {
  const { tealium } = useTealiumServiceProvider(currentUser, deviceRegion)
  return tealium
}

export const useTealium = () => {
  const tealium = useContext(TealiumServiceContext)
  return tealium
}

export const createTealiumService = (unviversalParams: TealiumUniversalParams): TealiumService => {
  const sendAnalytics = <T,>(params: TrackParams<T>): void => {
    if (typeof window?.utag === 'undefined') {
      return
    }
    window.utag.link<T>({
      ...unviversalParams,
      ...params,
      tealium_event: params.event_name
    })
  }

  // create TealiumService
  const tealiumService: TealiumService = {
    upsellOpen: () => {
      sendAnalytics({
        event_name: 'subscribe_modal_open'
      })
    },
    upsellClose: () => {
      sendAnalytics({
        event_name: 'subscribe_modal_close'
      })
    },
    upsellStartTrial: () => {
      sendAnalytics({ event_name: 'subscribe_modal-start_trial' })
    },
    upsellSignIn: () => {
      sendAnalytics({ event_name: 'subscribe_modal-sign_in' })
    },
    upsellSignInWithTVProvider: () => {
      sendAnalytics({ event_name: 'subscribe_modal-tv_provider' })
    },
    createAccount: (params: TealiumCreateAccountParams) => {
      sendAnalytics<TealiumCreateAccountParams>({
        event_name: 'create_account',
        ...params
      })
    },
    logIn: (params: TealiumLoginParams) => {
      sendAnalytics<TealiumLoginParams>({
        event_name: 'login',
        ...params
      })
    },
    logInMVPD: (params: TealiumLoginMVPDParams) => {
      sendAnalytics<TealiumLoginMVPDParams>({
        event_name: 'mvpd_login',
        ...params
      })
    },
    logOut: () => {
      sendAnalytics({
        event_name: 'log_out'
      })
    },
    changeEmail: () => {
      sendAnalytics({
        event_name: 'change_email'
      })
    },
    favoriteTeam: (params: TealiumFavoriteTeamParams) => {
      sendAnalytics<TealiumFavoriteTeamParams>({
        event_name: 'team_fav',
        ...params
      })
    },
    cancelSubscription: () => {
      sendAnalytics({
        event_name: 'cancel_subscription'
      })
    },
    restorePurchase: () => {
      sendAnalytics({
        event_name: 'restore_purchase'
      })
    },
    pauseSubscription: (params: TealiumPauseSubscriptionParams) => {
      sendAnalytics({
        event_name: 'pause_subscription',
        ...params
      })
    },
    resumeSubscription: () => {
      sendAnalytics({
        event_name: 'resume_subscription'
      })
    },
    updatePaymentMethod: (params: TealiumUpdatePaymentMethodParams) => {
      sendAnalytics<TealiumUpdatePaymentMethodParams>({
        event_name: 'update_payment_method',
        ...params
      })
    },
    packages: (params: TealiumPackagesParams) => {
      sendAnalytics<TealiumPackagesParams>({
        event_name: 'view_packages',
        ...params
      })
    },
    selectPackage: (params: TealiumSelectPackageParams) => {
      sendAnalytics<TealiumSelectPackageParams>({
        event_name: 'select_package',
        ...params
      })
    },
    upgradeToAnnual: (params: TealiumUpgradeToAnnualParams) => {
      sendAnalytics<TealiumUpgradeToAnnualParams>({
        event_name: 'upgrade_to_annual',
        ...params
      })
    },
    checkout: (params: TealiumCheckoutParams) => {
      sendAnalytics<TealiumCheckoutParams>({
        event_name: 'checkout',
        ...params
      })
    },
    purchase: (params: TealiumPurchaseParams) => {
      sendAnalytics<TealiumPurchaseParams>({
        event_name: 'purchase',
        ...params
      })
    },
    purchaseError: () => {
      sendAnalytics({
        event_name: 'purchase_error'
      })
    }
  }

  return tealiumService
}
