import { createContext, useEffect, useState } from 'react'
import { createUAParser, getDeviceType } from '../../hooks/useDeviceInfo'
import { ApiPlatform, Config } from './Config'

export type ConfigService = {
  appVersion: string
  apiEnvironment: string
  currentConfig?: Config
}

export const ConfigServiceContext = createContext<ConfigService>({
  apiEnvironment: '',
  appVersion: '',
  currentConfig: undefined
})

// https://server.com/configapi/<LASTKNOWN CHECKSUM>/X-APIversion/X-brand/X-appPlatform/X-appModel/X-appVersion/X-appOSversion/X-appLanguage
type ConfigURLProps = {
  checksum: string
  appVersion: string
  apiEnvironment: string // 0,6,8
  apiPlatform: ApiPlatform
}

const getBrowserVersion = (): string => {
  const ua = createUAParser()
  // get browser version
  const browser = ua.getBrowser()
  return browser?.version ?? '1.0.0'
}
const buildConfigURL = ({ checksum, appVersion, apiEnvironment, apiPlatform }: ConfigURLProps) => {
  const url = new URL('https://configapi.ballysports.com')
  url.pathname = [
    checksum,
    '1.0.6', // Config API Version
    'ballysports', // Brand
    apiPlatform, // Platform
    getDeviceType(), // Model
    `${appVersion}.${apiEnvironment}`, // App Version
    getBrowserVersion(), // Browser/OS Version
    'en-US' // Language
  ].join('/')

  return url.toString()
}

type ConfigServiceProviderProps = {
  appVersion: string

  /** The ConfigAPI environment to use */
  apiEnvironment: string
  apiPlatform: ApiPlatform
}

export const ConfigServiceProvider = ({
  children,
  appVersion,
  apiEnvironment,
  apiPlatform
}: React.PropsWithChildren<ConfigServiceProviderProps>) => {
  const [currentConfig, setCurrentConfig] = useState<Config | undefined>(undefined)
  const liveService: ConfigService = {
    appVersion,
    apiEnvironment,
    currentConfig: currentConfig
  }

  useEffect(() => {
    const fetchData = async () => {
      const checksum = currentConfig?.checksum ?? 'default'

      const response = await fetch(buildConfigURL({ checksum, appVersion, apiEnvironment, apiPlatform }))
      const config = await response.json()
      setCurrentConfig({
        ...config,
        apiPlatform
      })
    }
    fetchData()

    // We don't want to re-render after saving config
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appVersion])

  if (currentConfig === undefined) {
    return <></>
  }

  return <ConfigServiceContext.Provider value={liveService}>{children}</ConfigServiceContext.Provider>
}
