import { DeviceType } from '@grandstand/presentation-models/types/responses/device'
import { useState } from 'react'
import UAParser from 'ua-parser-js'
import { v4 as uuidv4 } from 'uuid'
import { useLocalStorage } from './useLocalStorage'

export type DeviceInfo = {
  device_name: string
  device_id: string
  device_type: DeviceType
}

export type CurrentDevice = DeviceInfo & {
  navigator: Navigator | null
  user_agent: string | null
  device_platform: ConnectedDevicePlatform
  device_is_safari: boolean
}

const generateDeviceId = (): string => {
  return uuidv4()
}

const DEVICE_ID_KEY = 'bally_device_id'

const getNavigator = () => window.navigator ?? navigator ?? null

export const getUserAgent = () => getNavigator()?.userAgent ?? null

export const createUAParser = () => {
  const userAgent = getUserAgent()
  return new UAParser(userAgent)
}

export type ConnectedDevicePlatform = Extract<DeviceType, 'tv_xboxone' | 'tv_samsung' | 'tv_generic'>

export const getConnectedDevicePlatform = (): ConnectedDevicePlatform => {
  if (typeof window === 'undefined') {
    return 'tv_generic'
  }
  const ua = getUserAgent().toLowerCase()
  const win = window as Window & {
    tizen?: any
    Windows?: any
  }
  const hasTizen = typeof win.tizen !== 'undefined' || /tizen|samsung/.test(ua)
  const hasXbox = typeof win.Windows !== 'undefined' || /xbox/.test(ua)
  if (hasXbox) {
    return 'tv_xboxone'
  }
  if (hasTizen) {
    return 'tv_samsung'
  }
  return 'tv_generic'
}

export const getDeviceType = (): DeviceType => {
  switch (getConnectedDevicePlatform()) {
    case 'tv_generic':
      return 'web_browser'
    case 'tv_samsung':
      return 'tv_samsung'
    case 'tv_xboxone':
      return 'tv_xboxone'
  }
}

// check if tizen
export const getDeviceIsTizen = (): boolean => {
  return getConnectedDevicePlatform() === 'tv_samsung'
}

// check if xbox
export const getDeviceIsXbox = (): boolean => {
  return getConnectedDevicePlatform() === 'tv_xboxone'
}
// check if browser is safari (exclude all other browsers). get true/false with regex
export const getDeviceIsSafari = (): boolean => {
  const device_type = getDeviceType()
  // Only browsers can be classified as safari
  if (device_type !== 'web_browser') {
    return false
  }
  const ua = getUserAgent().toLowerCase()
  const safariRegex = /^((?!chrome|android|chromium|edg).)*safari\//gim
  return safariRegex.test(ua)
}

export const getDeviceName = () => {
  const userAgent = getUserAgent()

  if (/xbox/.test(userAgent.toLowerCase())) {
    return 'Xbox'
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS Device'
  } else if (/Macintosh|Mac OS X/.test(userAgent)) {
    return 'Mac'
  } else if (/Windows/.test(userAgent)) {
    return 'Windows PC'
  } else if (/Android/.test(userAgent)) {
    return 'Android Device'
  } else if (/tizen|samsung/.test(userAgent.toLowerCase())) {
    return 'Samsung TV'
  } else {
    return 'Unknown Device'
  }
}

export type DeviceCategory = 'console' | 'tv' | 'tablet' | 'mobile' | 'desktop'
export const getDeviceCategory = () => {
  switch (getDeviceName()) {
    case 'Xbox':
      return 'console'
    case 'Samsung TV':
      return 'tv'
    case 'iOS Device':
    case 'Android Device':
      return window?.innerWidth > 768 ? 'tablet' : 'mobile'
    case 'Mac':
    case 'Windows PC':
    default:
      return 'desktop'
  }
}

export const getDeviceId = (): string => {
  const currentDeviceId = window?.localStorage?.getItem(DEVICE_ID_KEY)
  let device_id: string = currentDeviceId ?? generateDeviceId()
  if (!currentDeviceId) {
    window.localStorage.setItem(DEVICE_ID_KEY, device_id)
  }
  return device_id
}

export const getCurrentDevice = (): CurrentDevice => ({
  navigator: getNavigator(),
  user_agent: getUserAgent(),
  device_id: getDeviceId(),
  device_name: getDeviceName(),
  device_type: getDeviceType(),
  device_platform: getConnectedDevicePlatform(),
  device_is_safari: getDeviceIsSafari()
})

// AdInfo

// LAT acronym for "Limit Ad Tracking" (user has opted out of personalized ads)
enum AdIsLat {
  TRUE = '1',
  FALSE = '0'
}
type AdInfo = {
  did: string
  is_lat: AdIsLat
  device_make: string
  device_model: string
  platform: string
  osversion: string
}

// HELEPRS
const getWebAdInfo = (): AdInfo => {
  const ua = createUAParser()
  const device = ua.getDevice()
  const os = ua.getOS()
  return {
    platform: os?.name ?? 'Web',
    did: getDeviceId(),
    is_lat: AdIsLat.FALSE,
    device_make: device?.vendor ?? 'unknown',
    device_model: device?.model ?? 'unknown',
    osversion: os.version ?? 'unknown'
  }
}

const getXboxAdInfo = (): AdInfo => {
  const win = window as any
  const adManager = win?.Windows?.System?.UserProfile?.AdvertisingManager
  const did = adManager?.advertisingId ?? ''
  const ua = createUAParser()
  const os = ua.getOS()
  const device = ua.getDevice()
  const adInfo: AdInfo = {
    did: did?.length > 0 ? did : getDeviceId(),
    is_lat: did?.length > 0 ? AdIsLat.FALSE : AdIsLat.TRUE,
    platform: os?.name ?? 'Xbox',
    device_make: device?.vendor ?? 'Microsoft',
    device_model: device?.model ?? 'Xbox One',
    osversion: os?.version ?? 'One'
  }
  return adInfo
}

const getTizenAdInfo = async (): Promise<AdInfo> => {
  const ua = createUAParser()
  const os = ua.getOS()
  const device = ua.getDevice()
  const adInfo: AdInfo = {
    did: getDeviceId(),
    is_lat: AdIsLat.TRUE,
    platform: 'Tizen',
    device_make: device?.vendor ?? 'unknown',
    device_model: device?.model ?? 'unknown',
    osversion: os?.version ?? 'unknown'
  }
  try {
    const ti = await import('tizen-tv-webapis')
    try {
      adInfo.is_lat = ti.adinfo.isLATEnabled() ? AdIsLat.TRUE : AdIsLat.FALSE
    } catch (error) {
      console.warn(`adinfo: ERROR @ ti.adinfo.isLATEnabled() for is_lat: `, error)
    }
    try {
      adInfo.did = ti.adinfo.getTIFA()
    } catch (error) {
      console.warn(`adinfo: ERROR @ ti.adinfo.getTIFA() for did: `, error)
    }
    // get make from tizen
    try {
      // adInfo.device_make = ti.productinfo.getLicensedVendor() (`ti as any` because this exists on object, but not on type)
      adInfo.device_make = (ti as any).productinfo.getLicensedVendor()
    } catch (error) {
      console.warn(`adinfo: ERROR @ ti.productinfo.getLicensedVendor() for device_make: `, error)
    }

    // get model from tizen
    try {
      adInfo.device_model = ti.productinfo.getModel()
    } catch (error) {
      console.warn(`adinfo: ERROR @ ti.productinfo.getModel() for device_model: `, error)
    }
    // get osversion from tizen
    try {
      adInfo.osversion = ti.productinfo.getVersion()
    } catch (error) {
      console.warn(`adinfo: ERROR @ ti.productinfo.getVersion() for osversion: `, error)
    }
  } catch (_) {
    // Do nothing
  }
  return adInfo
}

export const getAdInfo = async (apiEnv: string, favoriteTeams?: string[]): Promise<AdInfo> => {
  let adInfo = getWebAdInfo()
  if (typeof window === 'undefined') {
    return adInfo
  }
  if (getDeviceIsTizen()) {
    adInfo = await getTizenAdInfo()
  } else if (getDeviceIsXbox()) {
    adInfo = getXboxAdInfo()
  } else {
    adInfo = getWebAdInfo()
  }
  return {
    ...adInfo,
    // encode these values, else playback will fail on xbox's /video page
    platform: encodeURIComponent(adInfo.platform),
    device_make: encodeURIComponent(adInfo.device_make),
    device_model: encodeURIComponent(adInfo.device_model),
    osversion: encodeURIComponent(adInfo.osversion)
  }
}

const useDeviceInfo = (): DeviceInfo => {
  const { device_id, device_name, device_type } = getCurrentDevice()
  return useState<DeviceInfo>({
    device_id: useLocalStorage<string>(DEVICE_ID_KEY, device_id)[0],
    device_name,
    device_type
  })[0]
}

export default useDeviceInfo
