let enabled = true

interface StandardLog {
  message: string
  codeLocation: string
  data?: any
}

type logLevel = 'info' | 'warn' | 'debug' | 'error'

const createLog = (codeLocation: string, message: string, data: any): StandardLog => ({
  message: `${codeLocation} > ${message}`,
  codeLocation,
  data
})

const doLog = (level: logLevel, codeLocation: string, message: string, data: any): void => {
  if (!enabled) {
    return
  }
  const log = createLog(codeLocation, message, data)
  switch (level) {
    case 'warn':
      console.warn(log)
      break
    case 'debug':
      console.debug(log)
      break
    case 'error':
      console.error(log)
      break
    default:
      console.log(log)
  }
}

export const Logger = {
  of: (codeLocation: string) => {
    return {
      info: (message: string, data?: any) => doLog('info', codeLocation, message, data),
      warn: (message: string, data?: any) => doLog('warn', codeLocation, message, data),
      error: (message: string, data?: any) => doLog('error', codeLocation, message, data),
      debug: (message: string, data?: any) => doLog('debug', codeLocation, message, data)
    }
  }
}

export const enableLogger = (isEnabled: boolean) => {
  enabled = isEnabled
}
