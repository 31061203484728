import { datadogRum } from '@datadog/browser-rum'
import getConfig from 'next/config'
import { useContext } from 'react'
import { ConfigServiceContext } from '../services/config/ConfigService'
import { Logger } from '../utils/logger'

type Services = 'web' | 'connected-web'

type DDRumProps = {
  service: Services
}

const DDRum = ({ service }: DDRumProps) => {
  const { currentConfig } = useContext(ConfigServiceContext)
  const { publicRuntimeConfig } = getConfig()
  if (!currentConfig) {
    return null
  }

  const sessionSampleRate = currentConfig.API?.features?.dd_rum_config?.session_sample_rate || 0
  const sessionReplaySampleRate = currentConfig.API?.features?.dd_rum_config?.session_replay_sample_rate || 0

  if (sessionSampleRate === 0) {
    return null
  }
  try {
    datadogRum.init({
      applicationId: publicRuntimeConfig.dataDogRumApplicationId,
      clientToken: publicRuntimeConfig.dataDogRumClientToken,
      site: 'datadoghq.com',
      service,
      env: publicRuntimeConfig.environment,
      sessionSampleRate,
      sessionReplaySampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input'
    })
  } catch (error) {
    Logger.of('DDRum').error('DD init error', {
      error
    })
  }
  return null
}

export default DDRum
